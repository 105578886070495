import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lang]/[partner]/(ActiveCustomer)/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Analytics/AnalyticsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Analytics/contexts/AnalyticsContext.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/Analytics/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/contexts/global/GlobalStoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","get"] */ "/vercel/path0/src/lib/utils/persistenceService.ts");
